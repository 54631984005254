import { ParsedQuery } from 'query-string';
import { PageType } from '../types';
import { AccountRedirectHandler } from './handlers/Account';
import { AmendmentRedirectHandler } from './handlers/Amendment';
import { InvoiceAdjustmentRedirectHandler } from './handlers/InvoiceAdjustment';
import { InvoiceItemAdjustmentRedirectHandler } from './handlers/InvoiceItemAdjustment';
import { SubscriptionRedirectHandler } from './handlers/Subscription';
import { CustomObjectRedirectHandler } from './handlers/CustomObject';
import { PaymentRedirectHandler } from './handlers/Payment';
import { NotificationsRedirectHandler } from './handlers/Notifications';
import { DataConnectRedirectHandler } from './handlers/DataConnect';
import { RefundRedirectHandler } from './handlers/Refund';

export type RedirectHandlerArgs = {
  location: URL;
  queryParams: ParsedQuery;
};

export type RedirectHandlerResult = {
  pageType: PageType;
  redirectUrl: string;
  params?: { filter?: object; [key: string]: any };
  isEnabledForOldUI?: boolean; // when true redirects regardless of the 'Enable New Experience' profile setting, default false
};

export type RedirectHandler = (
  args: RedirectHandlerArgs,
) => RedirectHandlerResult | undefined;

export const RedirectMap: { [pathname: string]: RedirectHandler } = {
  '/apps/Amendment.do': AmendmentRedirectHandler,
  '/apps/ARPayment.do': PaymentRedirectHandler,
  '/apps/NewPayment.do': PaymentRedirectHandler,
  '/apps/NewAmendment.do': AmendmentRedirectHandler,
  '/apps/CustomerAccount.do': AccountRedirectHandler,
  '/apps/Subscription.do': SubscriptionRedirectHandler,
  '/apps/Adjustment.do': InvoiceAdjustmentRedirectHandler,
  '/apps/InvoiceItemAdjustment.do': InvoiceItemAdjustmentRedirectHandler,
  '/platform/custom-objects': CustomObjectRedirectHandler,
  '/platform/data-connect': DataConnectRedirectHandler,
  '/apps/CustomEvent.do': NotificationsRedirectHandler,
  '/apps/BaseEmailTemplate.do': NotificationsRedirectHandler,
  '/apps/CalloutTemplate.do': NotificationsRedirectHandler,
  '/apps/NotificationHistory.do': NotificationsRedirectHandler,
  '/apps/ARRefund.do': RefundRedirectHandler,
};
