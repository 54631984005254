import { RedirectHandler } from '..';
import { PageType } from '../../types';

export const NotificationsRedirectHandler: RedirectHandler = ({
  location,
  queryParams,
}) => {
  if (location.href.includes('CustomEvent.do')) {
    return {
      pageType: PageType.list,
      redirectUrl:
        "/platform/apps/com_zuora/notifications?~(selectedTab~'custom-events-tab)",
      queryParams,
      isEnabledForOldUI: true,
    };
  } else if (location.href.includes('CalloutTemplate.do')) {
    return {
      pageType: PageType.list,
      redirectUrl:
        "/platform/apps/com_zuora/notifications?~(selectedTab~'callout-templates-tab)",
      queryParams,
      isEnabledForOldUI: true,
    };
  } else if (
    location.href.includes('BaseEmailTemplate.do') &&
    (!queryParams.method || queryParams.method === 'listTemplates')
  ) {
    return {
      pageType: PageType.list,
      redirectUrl:
        "/platform/apps/com_zuora/notifications?~(selectedTab~'email-templates-tab)",
      queryParams,
      isEnabledForOldUI: true,
    };
  } else if (
    location.href.includes('BaseEmailTemplate.do') &&
    !!queryParams.id
  ) {
    return {
      pageType: PageType.detail,
      // @ts-ignore
      redirectUrl: `/platform/apps/com_zuora/emailtemplate/${queryParams.id.replace(
        'NAE$',
        '',
      )}`,
      queryParams,
      isEnabledForOldUI: true,
    };
  } else if (location.href.includes('NotificationHistory.do')) {
    return {
      pageType: PageType.list,
      redirectUrl: `/platform/apps/com_zuora/notifications?~(selectedTab~'${
        queryParams?.method === 'viewCalloutHistory'
          ? 'callout-history-tab'
          : 'email-history-tab'
      })`,
      queryParams,
    };
  }

  return;
};
